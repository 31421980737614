import {DHContentBlockElement} from './DHContentBlockModel';
import RichTextBlock from '../richtext-block/RichTextBlock';
import {RichtTextBlockModel} from '../richtext-block/RichtTextBlockModel';
import HomeHeaderBlock from '../home-header-block/HomeHeaderBlock';
import {HomeHeaderBlockModel} from '../home-header-block/HomeHeaderBlockModel';
import FeaturePresenterBlock from '../feature-presenter-block/FeaturePresenterBlock';
import {FeaturePresenterBlockModel} from '../feature-presenter-block/FeaturePresenterBlockModel';
import DHRichTextBlock from '../dh-richtext-block/DHRichTextBlock';
import {DHRichTextBlockModel} from '../dh-richtext-block/DHRichTextBlockModel';
import DHDownloadBlock from '../dh-download-block/DHDownloadBlock';
import {DHDownloadBlockModel} from '../dh-download-block/DHDownloadBlockModel';
import RMSVerticalInfoBlock from '../rms-vertical-info-block/RMSVerticalInfoBlock';
import {RMSVerticalInfoBlockModel} from '../rms-vertical-info-block/RMSVerticalInfoBlockModel';
import RMSVerticalSpacingBlock from '../rms-vertical-spacing-block/RMSVerticalSpacingBlock';
import {RMSVerticalSpacingBlockModel} from '../rms-vertical-spacing-block/RMSVerticalSpacingBlockModel';
import RMSMultiSizeImageBlock from '../rms-multi-size-image-block/RMSMultiSizeImageBlock';
import {RMSMultiSizeImageBlockModel} from '../rms-multi-size-image-block/RMSMultiSizeImageBlockModel';
import RMSHTMLBlock from '../rms-html-block/RMSHTMLBlock';
import {RMSHTMLModel} from '../rms-html-block/RMSHTMLModel';

export default function DHContentBlock({blocks}: {blocks: DHContentBlockElement[]}) {

    const mapBlock = (block: DHContentBlockElement, index: number) => {
        switch (block.type) {
            case 'rms_rich_text':
                return <DHRichTextBlock key={index} blockElement={block as DHRichTextBlockModel} />;
            case 'rms_vertical_spacing':
                return <RMSVerticalSpacingBlock key={index} blockElement={block as RMSVerticalSpacingBlockModel} />;
            case 'dh_home_header':
                return <HomeHeaderBlock key={index} blockElement={block as HomeHeaderBlockModel} />;
            case 'dh_feature_presenter':
                return <FeaturePresenterBlock key={index} blockElement={block as FeaturePresenterBlockModel} />;
            case 'dh_download':
                return <DHDownloadBlock key={index} blockElement={block as DHDownloadBlockModel} />;
            case 'rms_multi_size_image':
                return <RMSMultiSizeImageBlock key={index} blockElement={block as RMSMultiSizeImageBlockModel} />;
            case 'html':
                return <RMSHTMLBlock key={index} blockElement={block as RMSHTMLModel} />;
            case 'rms_vertical_info':
                return <RMSVerticalInfoBlock key={index} blockElement={block as RMSVerticalInfoBlockModel} />;
            default:
                return <div key={index} color="red">No Rendering for {block.type}</div>
        }
    }

    return (<>
        { blocks.map((content: any, index: number) => mapBlock(content, index))}
        </>
    );
}