import {DHContentBlockElement} from '../dh-content-block/DHContentBlockModel';
import {RMSVerticalInfoBlockModel} from './RMSVerticalInfoBlockModel';
import styles from './RMSVerticalInfoBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import rmsWidthClass from '../../../../utils/rmsWidthClass';
import DHRichTextElement from '../../components/dh-rich-text-element/DHRichTextElement';
import {RMSVerticalInfoElement} from '../../components/rms-vertical-info-element/RMSVerticalInfoElement';
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl';
import RMSImageMultiSizeElement from '../../components/rms-image-multi-size-element/RMSImageMultiSizeElement';
import rmsMultiSizeImgValid from '../../components/rms-image-multi-size-element/utils/rmsMultiSizeImgValid';
import transformToReactStyle from '../../components/rms-css-style-block/transformToReactStyle';

export default function RMSVerticalInfoBlock({blockElement}: {blockElement: RMSVerticalInfoBlockModel}) {

    const blockValue = blockElement.value;

    return (
        <div className={styles.RMSVerticalInfoBlockContainer}>
            { rmsMultiSizeImgValid(blockValue.background_image) &&
                <div className={styles.backgroundImage}>
                    <RMSImageMultiSizeElement imageMultiSize={blockValue.background_image!} />
                </div>
            }
            { blockValue.content && <DHRichTextElement dhRichText={blockValue.content} />}
            <div className={
                multipleClassName(styles.RMSVerticalInfoBlock, rmsWidthClass(blockValue.width))
            }
                 style={transformToReactStyle(blockValue.css_style)}
            >
                {blockValue.elements && blockValue.elements.map((element, index) => {
                    return <RMSVerticalInfoElement key={index} infoElement={element}/>
                })}
            </div>
        </div>
    );
}