import styles from './MainFooter.module.scss';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useRMSApiConfig} from '../../../../features/api/contexts/RMSApiContext';
import {useEffect} from 'react';
import {fetchFlatMenu} from '../../../../features/cms/menus/actions/fetchFlatMenu';
import {useFlatMenuWithState} from '../../../../features/cms/hooks/useFlatMenuSelector';

function MenuItem({name = "Menu Item", menuLink= "/"}) {

    return (
        <Link to={menuLink} className="rms-clickable rms-hover-scale">{name}</Link>
    )
}

export default function MainFooter(){
    const dispatch = useDispatch();
    const apiConfig = useRMSApiConfig();
    const {
        footerMenu,
        loadingState: menuLoadingState,
        error: menuError} = useFlatMenuWithState();

    useEffect(() => {
        dispatch(fetchFlatMenu({idOrHandle: 'footer', apiConfig: apiConfig}) as any);
    }, [dispatch]);

    return (
        <div className={styles.MainFooter}>
            <div className={styles.MainFooter__Inner}>
                <div className={styles.MainFooter_Line}>
                    <div className={styles.MenuContainer}>
                        {
                            footerMenu && footerMenu.menu_items.map((item, index) => {
                                return <MenuItem key={index} name={item.menu_text} menuLink={item.path}/>
                            })
                        }
                        {
                            menuLoadingState === 'loading' && <div>Loading...</div>
                        }
                        {
                            menuError && <div>Error loading menu</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}