import RMSCSSStyleModel from './RMSCSSStyleModel';

export default function transformToReactStyle(styleObject?: RMSCSSStyleModel): React.CSSProperties {
    const reactStyle: React.CSSProperties = {};

    if (!styleObject) {
        return reactStyle;
    }

    if (styleObject.padding) {
        reactStyle.padding = styleObject.padding;
    }

    if (styleObject.margin) {
        reactStyle.margin = styleObject.margin;
    }

    if (styleObject.color) {
        reactStyle.color = styleObject.color;
    }

    if (styleObject.background) {
        reactStyle.background = styleObject.background;
    }

    if (styleObject.border_radius) {
        reactStyle.borderRadius = styleObject.border_radius;
    }

    if (styleObject.border) {
        reactStyle.border = styleObject.border;
    }

    if (styleObject.box_shadow) {
        reactStyle.boxShadow = styleObject.box_shadow;
    }

    if (styleObject.width) {
        reactStyle.width = styleObject.width;
    }

    if (styleObject.height) {
        reactStyle.height = styleObject.height;
    }

    return reactStyle;
}