import React from 'react';
import logo from './logo.svg';
import './App.css';
import TopHeader from './app/main/elements/header/TopHeader/TopHeader';
import RootPage from './app/main/elements/pages/RootPage/RootPage';
import {BrowserRouter, createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom';
import MainFooter from './app/main/elements/footer/MainFooter/MainFooter';

function AppLayout() {
    return (
        <div className="App" data-testid="app">
            <TopHeader data-testid="top-header"/>
            <Outlet/>
            <MainFooter data-testid="main-footer"/>
        </div>
    );
}

function App() {
    const router = createBrowserRouter([
        {
            element: <AppLayout />,
            children: [
                {
                    path: "/",
                    element: <RootPage />,
                },
                {
                    path: "/*",
                    element: <RootPage />,
                },
            ]
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default App;
