import {BlogPageModel} from './BlogPageModel';
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock';
import styles from './BlogPage.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';

export default function BlogPage({page}: {page: BlogPageModel}) {

    const getPageStyle = () => {
        switch (page.page_styles) {
            case "only_text": return styles.onlyText;
            default: return "";
        }
    }

    return (<div className={multipleClassName(styles.BlogPage, getPageStyle())}>
        {
            page.show_title && <div className={styles.BlogPage__Header}>
                <h1>{page.title}</h1>
            </div>
        }
        {
            <DHContentBlock blocks={page.content}/>
        }
    </div>)
}