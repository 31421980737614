// store.ts
import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import {rmsCmsReducer} from '../features/cms/reducers/rmsCmsReducer';

export interface RootState {
    api: ReturnType<typeof apiReducer>
}

const apiReducer = combineReducers({
    cms: rmsCmsReducer
});

const rootReducer = combineReducers({
    api: apiReducer
});

export const store = configureStore({
 reducer: rootReducer,
});

export default store;