import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './app/main/store';
import 'bootstrap-icons/font/bootstrap-icons.css'
import RMSApiContext, {RMSApiContextProps} from './app/features/api/contexts/RMSApiContext';
import {defaultRMSApiConfig} from './app/features/api/models/RMSApiConfig';


const rmsApiContextProps: RMSApiContextProps = {
    config: {
        ...defaultRMSApiConfig,
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        }
    }
}

ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
).render(
    <React.StrictMode>
        <Provider store={store}>
            <RMSApiContext.Provider value={rmsApiContextProps}>
                <App />
            </RMSApiContext.Provider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
