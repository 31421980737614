import {createAsyncThunk} from '@reduxjs/toolkit';
import {RMSCMSPage} from '../models/RMSCMSPage';
import axios from 'axios';
import RMSApi from '../../../api/RMSApi';
import {useRMSApiConfig} from '../../../api/contexts/RMSApiContext';
import RMSApiConfig from '../../../api/models/RMSApiConfig';

export const fetchPage = createAsyncThunk(
    'api/cms/page/fetchPage',
    async (payload: {
                pageId: string,
                apiConfig: RMSApiConfig
           }) => {
        try {
            const response = await RMSApi.get(`/api/v2/pages/find/`, {
                ...payload.apiConfig,
                params: {
                    html_path: payload.pageId
                }
            });
            const pageData: RMSCMSPage = response.data;
            return pageData;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);