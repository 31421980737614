import RMSCSSStyleModel from './RMSCSSStyleModel';
import multipleClassName from '../../../../utils/multipleClassName';

export default function transformToReactContainerClasses(styleObject?: RMSCSSStyleModel): string {
    const reactClasses :string[] = [];

    if (!styleObject) {
        return multipleClassName(...reactClasses);
    }

    if (styleObject.ignore_max_width) {
        reactClasses.push("IgnoreMaxWidth");
    }

    return multipleClassName(...reactClasses);
}