import styles from './RMSVerticalInfoElement.module.scss';
import RMSVerticalInfoElementModel from './RMSVerticalInfoElementModel';
import DHRichTextElement from '../dh-rich-text-element/DHRichTextElement';
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl';
import {Link} from 'react-router-dom';
import multipleClassName from '../../../../utils/multipleClassName';
import RMSChoosedImageElement from '../rms-choosed-image-element/RMSChoosedImageElement';
import transformToReactStyle from '../rms-css-style-block/transformToReactStyle';

export function RMSVerticalInfoElement({infoElement}: {infoElement: RMSVerticalInfoElementModel}) {

    const headerLayoutClass = () => {
        switch (infoElement.layout_style) {
            case "img-title-sub": return styles.imgTitleSub;
            case "title-img-sub": return styles.titleImgSub;
            case "title-sub-img": return styles.titleSubImg;
            default: return "";
        }
    }

    const colorStyleClass = () => {
        switch (infoElement.color_style) {
            case "light": return styles.colorStyleLight;
            case "dark": return styles.colorStyleDark;
            case "light-highlight": return styles.colorStyleLightHighlight;
            case "dark-highlight": return styles.colorStyleDarkHighlight;
            default: return "";
        }
    }

    return (
        <div className={styles.RMSVerticalInfoElement} style={transformToReactStyle(infoElement.css_style)}>
            <div className={multipleClassName(styles.RMSVerticalInfoElement__Inner, colorStyleClass())}>
                <div className={multipleClassName(styles.headerContainer, headerLayoutClass())}>
                    <div className={styles.imageContainer}>
                        { infoElement.image?.image &&
                            <RMSChoosedImageElement choosedImage={infoElement.image} /> }
                        { infoElement.icon && <div
                            className={styles.elementIcon}
                            dangerouslySetInnerHTML={{ __html: infoElement.icon }}></div> }
                    </div>
                    { infoElement.title && <h3 className={styles.title}>{infoElement.title}</h3> }
                    { infoElement.subtitle && <h4 className={styles.subtitle}>{infoElement.subtitle}</h4> }
                </div>
                { infoElement.content && <DHRichTextElement dhRichText={{
                    rich_text: infoElement.content
                }}/>}
                { infoElement.show_more_text && infoElement.show_more_link &&
                    <div className={styles.buttonContainer}>
                        <Link to={infoElement.show_more_link}><button className="primary rms-hover-scale">{infoElement.show_more_text}</button></Link>
                    </div>
                }
            </div>
        </div>
    )
}