import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {fetchPage} from '../../../../features/cms/pages/actions/fetchPage';
import {usePageWithState} from '../../../../features/cms/hooks/usePageSelector';
import {LoadingState} from '../../../../features/api/models/LoadingState';
import {BlogPageModel} from '../BlogPage/BlogPageModel';
import BlogPage from '../BlogPage/BlogPage';
import {useRMSApiConfig} from '../../../../features/api/contexts/RMSApiContext';
import styles from './RootPage.module.scss';
import loadingSpinner from '../../../../../assets/images/loading_spinner.svg'
import {Simulate} from 'react-dom/test-utils';
import multipleClassName from '../../../../utils/multipleClassName';
import RMSDialog from '../../../../features/rms-react-dialogs/components/rms-dialog/RMSDialog';
import RMSDialogCard from '../../../../features/rms-react-dialogs/components/rms-dialog-card/RMSDialogCard';
import {setDHTitle} from '../../../../utils/dhSetTitle';

export default function RootPage(){
    const path = useLocation().pathname;
    const normalizedPath = ((path == "" || path == "/") ? "/home" : path);
    const [closeDialog, setCloseDialog] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const dispatch = useDispatch();
    const apiConfig = useRMSApiConfig();

    const {
        page,
        loadingState: pageLoadingState,
        error: pageError} = usePageWithState(normalizedPath);

    const loadPage = () => {
        dispatch(fetchPage({pageId: normalizedPath, apiConfig: apiConfig}) as any);
    }

    useEffect(() => {
        loadPage();
    }, [path]);

    useEffect(() => {
        setDHTitle(page?.title)
    }, [page])

    console.log("page", page, pageError);

    if (dialogVisible) {
        return (
            <RMSDialog startClose={closeDialog} onClickBackground={() => {
                setCloseDialog(true);
                setTimeout(() => setDialogVisible(false), 1000);
            }}>
                <RMSDialogCard title="Hello" startClose={closeDialog}>
                    <p>Callback Value: Ensure that the custom property has a valid fallback value (500px in your case) that is appropriate for use as a max-width value in a media query.
                        Correct Usage: Double-check that there are no syntax errors or typos in the code. The provided code snippet appears to be syntactically correct.
                        Scope: Verify that the custom property is in scope where you are using it. If the custom property is defined in a different CSS file or a different part of your application, ensure that it is accessible where you are using it.
                        If you've checked all of these aspects and the issue persists, please provide more details about any error messages or unexpected behavior you're encountering, and I'll be happy to assist further.
                    </p>
                </RMSDialogCard>
            </RMSDialog>);
    }

    if (pageLoadingState === LoadingState.Loading) {
        return <div className={styles.rootPageLoading}>
            <div className={styles.loadingMidContainer}>
                <img src={loadingSpinner} alt="Loading Spinner" />
                <h2>Einen Moment bitte ...</h2>
            </div>
        </div>;
    }

    if (pageError) {
        return <div className={styles.rootPageLoading}>
            <div className={styles.loadingMidContainer}>
                <div className={styles.errorContainer}>
                    <h2 className={styles.errorTitle}>Ups</h2>
                    <h3 className={styles.errorSubtitle}>Es ist ein Fehler aufgetreten, versuche es noch einmal, oder kontaktiere uns, wenn der Fehler weiter bestehen bleibt.</h3>
                    <div className={styles.errorContent}>
                        {pageError?.message} {pageError?.statusCode}
                    </div>
                    <button onClick={loadPage} className={multipleClassName("rms-hover-scale")}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </button>
                </div>
            </div>
        </div>;
    }

    let castedPage = undefined;
    let pageComponent = undefined;

    switch (page?.meta.type) {
        case 'blog.BlogPage':
            castedPage = page as BlogPageModel;
            pageComponent = <BlogPage page={castedPage}/>;
    }

    if (pageComponent) {
        return pageComponent;
    }

    return null;
}