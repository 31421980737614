import { useSelector } from 'react-redux';
import {RootState} from '../../../main/store';
import {useApiContainer} from '../../api/hooks/useApiContainer';
import {RMSCMSPage} from '../pages/models/RMSCMSPage';

export function usePageData(path: string) {
    return useApiContainer<RootState, RMSCMSPage>((state: RootState) => state.api.cms.pages.find((page) => page.urlRequestPart === path));
    return useSelector((state: RootState) =>
        state.api.cms.pages.find((page) => page.urlRequestPart === path));
}

export function usePageWithState(path: string) {
    const pageData = usePageData(path);
    return {
        page: pageData?.payload,
        loadingState: pageData?.loadingState,
        error: pageData?.error
    };
}
