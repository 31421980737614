import styles from './DownloadElement.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import googlePlayImage from '../../../../../assets/images/google_play_button_de.png'
import appStoreImage from '../../../../../assets/images/app_store_button_de.svg'

export function DownloadElement() {
    return (
        <div className={styles.DownloadElement}>
            <a className={multipleClassName(styles.appStoreButton, "rms-hover-scale")} href='https://apps.apple.com/at/app/daily-help/id1608055376'>
                <img alt='Jetzt im App Store' src={appStoreImage}/>
            </a>
            <a className={multipleClassName(styles.playStoreButton, "rms-hover-scale")} href='https://play.google.com/store/apps/details?id=at.dailyhelp&hl=de_AT&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Jetzt bei Google Play' src={googlePlayImage}/>
            </a>
        </div>
    )
}