import {HomeHeaderBlockModel} from './HomeHeaderBlockModel';
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl';
import styles from './HomeHeaderBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import {useEffect, useState} from 'react';
import {DownloadElement} from '../../components/download-element/DownloadElement';
import RMSChoosedImageElement from '../../components/rms-choosed-image-element/RMSChoosedImageElement';
import RMSImageMultiSizeElement from '../../components/rms-image-multi-size-element/RMSImageMultiSizeElement';
import rmsMultiSizeImgValid from '../../components/rms-image-multi-size-element/utils/rmsMultiSizeImgValid';

export default function HomeHeaderBlock({blockElement}: {blockElement: HomeHeaderBlockModel}) {
    const blockValue = blockElement.value;
    const [scrollY, setScrollY] = useState(0);

    // Update the scrollY value whenever the user scrolls
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const opacity = 1 - scrollY / 200;

    return (
        <div
            className={multipleClassName(styles.HomeHeaderBlock, "IgnoreMaxWidth")}
            style={{
                background: (blockValue.background_image?.image_versions.length) ? "transparent" : "var(--rms-color-primary)"
            }}
        >
            { rmsMultiSizeImgValid(blockValue.background_image) && <div className={styles.backgroundImage}>
                <RMSImageMultiSizeElement imageMultiSize={blockValue.background_image!} />
            </div> }
            <div className={styles.inner}>
                <div className={styles.innerRightSide}>
                    <h1 className="glowing-text">{blockValue.title}</h1>
                    <h2>{blockValue.subtitle}</h2>
                    { blockValue.header_content && <div className={styles.headerContent} dangerouslySetInnerHTML={{ __html: blockValue.header_content }} /> }
                    <DownloadElement />
                </div>
                <div>
                    { rmsMultiSizeImgValid(blockValue.image) &&
                        <RMSImageMultiSizeElement imageMultiSize={blockValue.image!}
                                                  preferredWidth={200}
                                                  className={styles.HomeHeaderBlock__Screenshot} /> }
                </div>
            </div>

            { blockValue.show_more_text &&
            <div style={{
                opacity: opacity >= 0 ? opacity : 0,
                display: opacity >= 0 ? 'flex' : 'none'
            }}
                className={styles.showMoreTextContainer}>
                <div className={multipleClassName(styles.box, "rms-hover-scale", "rms-clickable")}>
                    {blockValue.show_more_text}
                    <i className="bi bi-caret-down"></i>
                </div>
            </div>
            }
        </div>
    );
}