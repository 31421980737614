import styles from './DHRichTextElement.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import rmsWidthClass from '../../../../utils/rmsWidthClass';
import DHRichText from './DHRichTextModel';
import rmsAlignmentClass from '../../../../utils/rmsAlignmentClass';
import RMSImageMultiSizeElement from '../rms-image-multi-size-element/RMSImageMultiSizeElement';
import rmsMultiSizeImgValid from '../rms-image-multi-size-element/utils/rmsMultiSizeImgValid';
import transformToReactStyle from '../rms-css-style-block/transformToReactStyle';

export default function DHRichTextElement({dhRichText}: {dhRichText: DHRichText}) {
    return (
        <div className={multipleClassName(styles.RichTextElementContainer, "IgnoreMaxWidth")}>
            <div className={styles.backgroundImage}>
                { rmsMultiSizeImgValid(dhRichText.background_image) && <RMSImageMultiSizeElement imageMultiSize={dhRichText.background_image!} /> }
            </div>
            <div className={
                multipleClassName(styles.RichTextElement, rmsWidthClass(dhRichText.width), rmsAlignmentClass(dhRichText.alignment))
            }
                 style={transformToReactStyle(dhRichText.css_style)}
                 dangerouslySetInnerHTML={{ __html: dhRichText.rich_text }} />
        </div>
    );
}